<template>
  <div class="DrawerWrapper">
    <Drawer ref="Drawer">
      <template #header>
        <div class="Header">
          <h2>detalhes da ocorrência</h2>
          <p>{{ data.datetime }}</p>
        </div>
      </template>

      <div class="Content">
        <div>
          <h4>motorista</h4>
          <p class="Driver">
            {{ data.driver }}
          </p>
          <p>placa<span>{{ data.plate }}</span></p>
          <p>rota<span>{{ data.route }}</span></p>
        </div>
        <div class="Reason">
          <h4>motivo</h4>
          <div class="CommentWrapper">
            <BaseTag :value="data.reason" read-only />
            <div v-if="data.comment" class="Comment">
              <Icon name="comment" />
              <span>{{ `”${data.comment}”` }}</span>
            </div>
          </div>
        </div>
        <div class="PackageDetail">
          <div>
            <h4>detalhes do pacote</h4>
            <p>cód. rastreio<span>{{ data.trackingCode }}</span></p>
            <p>nota fiscal<span>{{ data.nfe }}</span></p>
          </div>
          <div>
            <p>cliente<span>{{ data.client }}</span></p>
            <p>endereço<span>{{ data.address }}</span></p>
            <p>complemento<span>{{ data.complement }}</span></p>
            <p>bairro<span>{{ data.neighborhood }}</span></p>
            <p>cidade<span>{{ data.city }}</span></p>
            <p>estado<span>{{ data.state }}</span></p>
            <p>CEP<span>{{ data.cep }}</span></p>
          </div>
        </div>
      </div>

      <BaseButton
        color="primary-base"
        label="fechar"
        class="CloseButton"
        @click="$refs.Drawer.close()"
      />
    </Drawer>
  </div>
</template>

<script>
import { orders } from '@/api'
import { mapActions } from 'vuex'
import { Drawer } from '@/components/molecules'
import { BaseButton, BaseTag } from '@/components/atoms'
import mask from '@/plugins/mask/Mask.js'

export default {
  name: 'OccurrenceDetail',
  components: {
    BaseButton,
    BaseTag,
    Drawer
  },
  data() {
    return {
      data: {
        datetime: '',
        driver: '',
        plate: '',
        route: '',
        reason: '',
        comment: '',
        trackingCode: '',
        nfe: '',
        client: '',
        address: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        cep: '',
      }
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
    }),

    open(id, props = {}) {
      this.data = { ...props }
      this.fetchData(id)
      this.$refs.Drawer.open()
    },

    fetchData (id) {
      this.setLoading(true)
      orders.fetchOccurrenceOrderDetail({ id }, (res) => {
        this.data = {
          ...this.data,
          plate: res.driver?.vehicle?.plate ? mask.carPlate(res.driver.vehicle.plate) : null,
          route: res.route?.route?.name,
          comment: res.last_status_history?.observations,
          nfe: res.invoice_number,
          client: res.estimated_receiver?.name,
          address: res.order_addresses_id?.destiny_address,
          complement: res.order_addresses_id?.destiny_complement,
          neighborhood: res.order_addresses_id?.destiny_neighborhood,
          city: res.order_addresses_id?.destiny_city,
          state: res.order_addresses_id?.destiny_state_id?.abbreviation,
        }
      }, (e) => e, () => this.setLoading(false))
    }
  }
}
</script>

<style lang="scss" scoped>
.DrawerWrapper {
  /deep/ {
    .Drawer__Header {
      padding: $spacing-stack-1xlarge;
    }
    .Drawer__Body {
      padding: 0 $spacing-stack-1xlarge $spacing-stack-1xlarge;
    }
  }
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: $spacing-stack-large 0 $spacing-stack-medium 0;

  h2 {
    font-size: $font-size-1000;
    line-height: $line-height-600;
    font-weight: $font-weight-200;
    color: $color-gray-800;
  }
  p {
    font-size: $font-size-400;
    line-height: $line-height-500;
    font-weight: $font-weight-100;
    color: $color-gray-600;
  }
}
.Content {
  display: flex;
  flex-direction: column;
  gap: $spacing-700;
  margin-bottom: $spacing-800;
  color: $color-gray-800;
  font-weight: $font-weight-200;
  line-height: $line-height-500;

  p {
    font-size: $font-size-400;
  }
  span {
    margin-left: $spacing-300;
    color: $color-gray-600;
    font-size: $font-size-600;
    font-weight: $font-weight-100;
  }

  .Driver {
    font-size: $font-size-1200;
    line-height: $line-height-700;
  }
  .Reason {
    /deep/ .BaseTag {
      width: fit-content;
      padding: $spacing-200 $spacing-400;
      background: $color-primary-stronger;
      border-radius: $border-radius-pill;

      div {
        margin: 0;
      }
      span {
        font-size: $font-size-400;
        font-weight: $font-weight-200;
        line-height: $line-height-500;
      }
    }

    h4 {
      margin-bottom: $spacing-300;
    }

    .CommentWrapper {
      display: flex;
      flex-direction: column;
      gap: $spacing-500;
      padding-left: $spacing-300;

      .Comment {
        display: flex;
        height: 48px;
        padding-left: $spacing-400;

        img {
          width: 16px;
          height: 16px;
          filter: invert(30%);
          margin-top: $spacing-200;
        }
        span {
          margin-left: $spacing-400;
        }
      }
    }
  }
  .PackageDetail {
    display: flex;
    flex-direction: column;
    gap: $spacing-500;

    h4 {
      margin-bottom: $spacing-400;
    }
    p:not(:last-child) {
      margin-bottom: $spacing-200;
    }
  }
}
.CloseButton {
  width: 74px;
  height: 38px;
}
</style>