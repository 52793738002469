<template>
  <div class="DeliveryFailure">
    <TheSubHeader
      icon="package-request-fail"
      title="Insucesso de entrega"
      page-title-tag="Insucesso de entrega"
      type="filter"
      :actions="['back']"
      organizer-columns="240px repeat(4, 1fr) 100px"
    >
      <template #beforeButtons>
        <BaseButton
          v-if="permissions && permissions.orders && permissions.orders.incomingOrderButton"
          icon="package-request"
          color="dark"
          label="Recebimento de físico"
          :track="trackSubheaderButtonString('Delivery Failure', 'Orders Incoming')"
          @click="goToIncomingOrders"
        />
      </template>
      <template #filter>
        <!-- Period -->
        <BaseDatePicker
          v-model="formattedDateRange"
          label="filtrar por data"
          :max-range="7"
          @input="search"
        />
        <!-- Driver -->
        <BaseTreeSelect
          v-model="filters.drivers"
          name="drivers"
          label="drivers"
          placeholder="selecione"
          multiple
          suggestions="searchDrivers"
          @input="search"
        />
        <!-- Store -->
        <BaseTreeSelect
          ref="lojista"
          v-model="filters.store"
          name="lojista"
          label="lojista"
          placeholder="selecione"
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="search(); storeUpdate()"
        />
        <!-- Reason -->
        <BaseTreeSelect
          v-model="filters.reasons"
          name="motivos"
          label="motivos"
          placeholder="selecione"
          multiple
          :read-only="!filters.store"
          :select-options="status"
          @input="search"
        />
        <!-- Query -->
        <BaseTreeSelect
          ref="query"
          v-model="filters.query"
          name="buscar"
          label="buscar"
          placeholder="busque por cód rastreio"
          multiple
          :select-all="false"
          :enable-paste="true"
          :allow-create="true"
          :disable-branch-nodes="true"
          icon="money"
          append-icon="money"
          @input="search"
        />
        <!-- Filter -->
        <BaseButton
          label="filtrar"
          theme="icon-left"
          color="primary-base"
          filled
          icon="filter"
          text-color="white"
          @click="search()"
        />
      </template>
    </TheSubHeader>
    <section class="Content">
      <div class="Table">
        <DataTable
          :header="deliveryFailures.data.headers"
          :table="tableContent"
          :sort-columns="sortColumns"
          @see="openOccurrenceDetail"
          @sort="sort"
        />
        <Pagination v-if="deliveryFailures.data.content.length" @page="search()" @paginate="search()" />
      </div>
    </section>

    <!-- Occurrence Detail -->
    <OccurrenceDetail ref="OccurrenceDetail" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { TheSubHeader } from '@/components/organisms'
import { DataTable, Pagination } from '@/components/molecules'
import { BaseTreeSelect, BaseButton, BaseDatePicker } from '@/components/atoms'
import OccurrenceDetail from '@/views/orders/DeliveryFailure/OccurrenceDetail'
import mask from '@/plugins/mask/Mask.js'

export default {
  name: 'Orders',
  components: {
    BaseButton,
    BaseDatePicker,
    BaseTreeSelect,
    DataTable,
    OccurrenceDetail,
    TheSubHeader,
    Pagination
  },
  data() {
    return {
      filters: {
        dateRange: {
          init: null,
          end: null,
        },
        drivers: [],
        store: null,
        reasons: [],
        query: [],
      },
      tableContent: [],
      dateNow: 0,
      disconnectedTimeout: null,
      refreshTableTimeout: null,
      sortColumns: {
        occurrence_date: {
          direction: 'desc'
        }
      }
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.auth.permissions,
      status: state => state.orders.status,
      deliveryFailures: state => state.orders.deliveryFailures,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
    }),

    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },

    dateRangeInit() {
      const days = 15
      const dateOffset = (24*60*60*1000) * days
      const calculatedDate = new Date()
      calculatedDate.setTime(calculatedDate.getTime() - dateOffset)
      return calculatedDate.getTime()
    },

    dateRangeEnd() {
      const calculatedDate = new Date()
      const dateNow = new Date(calculatedDate)
      return dateNow.getTime()
    },
  },
  mounted() {
    this.listenChanges()
    this.formatTableWarning()
    this.refreshTableTimeout = setInterval(this.refreshTable.bind(this) , 1000)
    this.setInitialDate()
    this.clearPagination(200).then(() => this.search())
  },
  destroyed() {
    clearInterval(this.refreshTableTimeout)
    clearInterval(this.disconnectedTimeout)
    this.$echo.leave('delivery_occurrences')
  },
  methods: {
    ...mapActions({
      fetchDeliveryFailures: 'orders/fetchDeliveryFailures',
      getStatus: 'orders/getStatus',
      setLoading: 'loading/setLoading',
      setPagination: 'pagination/setPagination',
      clearPagination: 'pagination/clearPagination',
    }),

    setInitialDate() {
      this.$set(this.filters.dateRange, 'init', this.dateRangeInit)
      this.$set(this.filters.dateRange, 'end', this.dateRangeEnd)
    },

    goToIncomingOrders() {
      this.$router.push('/orders/incoming')
    },

    storeUpdate(){
      this.filters.reasons = []
      if (this.filters.store) this.getStatus({id: this.filters.store.id})
    },

    refreshTable() {
      this.formatTableWarning()
    },

    formatTableWarning() {
      let nowDate = new Date()
      let yesterdayDateStart = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1, 0, 0, 0, 0)
      let todayDateStart = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 0, 0, 0, 0)
      let todayDateLimit = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 12, 0, 0, 0)
      this.tableContent = this.deliveryFailures.data.content.map(t => {
        let cell = {
          ...t,
          occurrence_date: {
            value: t.occurrence_date
          }
        }
        let orderDate = new Date(t.occurrence_date)
        let orderDateStart = new Date(orderDate.getFullYear(), orderDate.getMonth(), orderDate.getDate(), 0, 0, 0, 0)
        if (
          orderDateStart < yesterdayDateStart ||
          orderDateStart >= yesterdayDateStart &&
          nowDate >= todayDateLimit &&
          orderDateStart < todayDateStart
        ) {
          cell.occurrence_date.features = {
            warning: {
              value: 'alert'
            }
          }
        } else if (
          orderDateStart < todayDateStart &&
          nowDate < todayDateLimit
        ) {
          cell.occurrence_date.features = {
            warning: {
              value: 'notice'
            }
          }
        }
        return cell
      })
    },

    search() {
      const params = {
        obj: {
          date_start: this.filters.dateRange.init,
          date_end: this.filters.dateRange.end,
          'filter[order_status][store_status_type_id]': [{ id: '__const:storeStatusType__insucessoEntrega' }],
          'filter[driver][drivers.id]': this.filters.drivers,
          store_status_id: this.filters.reasons,
          query: this.filters.query,
        },
        paginate: this.paginate,
        page: this.page,
        sort: this.sortColumns.occurrence_date.direction
      }

      this.fetchDeliveryFailures(params)
        .then(() => {
          this.setPagination(this.deliveryFailures)
        })
    },

    listenChanges() {
      this.$echo.channel('delivery_occurrences').listen('.refresh', () => {
        if(!this.firstSearch)
          this.clearPagination().then(() => this.search())
      })
      // If websocket doesn't have connection, run search method with interval of 20s
      this.disconnectedTimeout = setTimeout(() => {
        if (!window.echo?.connector?.socket?.connected) {
          this.search()
        }
      }, 20 * 1000)
    },

    sort(el) {
      const direction = this.sortColumns[el.cell.id].direction
      const newDirection = (direction === 'asc') ? 'desc' : 'asc'
      this.sortColumns[el.cell.id].direction = newDirection
      this.search()
    },

    openOccurrenceDetail(el) {
      this.$refs.OccurrenceDetail.open(
        el.order_id,
        {
          datetime: mask.millisecondsToDateHour(el.occurrence_date?.value),
          driver: el.driver_name,
          trackingCode: el.order_number,
          cep: mask.cep(el.destiny_zip),
          reason: el.store_status_name?.value,
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.DeliveryFailure {
  .Content {
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: $viewport-lg;
    margin-bottom: $spacing-stack-2xlarge;

    .Table {
      width: 100%;
      margin-bottom: $spacing-700;
    }
  }
}
</style>